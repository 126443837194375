/* @flow */

import * as React from 'react'

import { PayCallbackTemplate } from '../templates'
import { getQueryVariable } from '../utils'

type Props = {
  location: {
    href: string,
    search: string,
  },
}

export default function PayAccountSuccess(props: Props) {
  const redirect_uri = getQueryVariable(props.location, 'redirect_uri')
  const access_token = getQueryVariable(props.location, 'access_token')
  const refresh_token = getQueryVariable(props.location, 'refresh_token')
  const callbackLink = `${redirect_uri}?access_token=${access_token}&refresh_token=${refresh_token}`
  return (
    <PayCallbackTemplate
      url={props.location.href}
      name="Wino"
      title={'Vous avez été authentifié avec succès'}
      description="Retournez sur l'application pour finaliser l'authentification Wino de l'appareil"
      callbackText={redirect_uri && 'Finaliser la configuration'}
      callbackLink={redirect_uri && callbackLink}
    />
  )
}
